
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'app-telecom-list-page',
  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,
      action: '',
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_labtop: 'layout/labtop',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      rows: 'telecom/view/rows',
      current: 'branch/view/branch',
      loading: 'telecom/view/loading',
      pagination: 'telecom/view/pagination',
    }),
    isDark() {
      return this.$q.dark.isActive;
    },
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
    paginationPage() {
      return this.page - 1 || 0;
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'telecom/view/doFetch',
    }),
    next() {
      this.page++;
      this.action = 'next';
    },
    prev() {
      this.page--;
      this.action = 'prev';
    },
  },
  watch: {
    current() {
      this.page = 1;
      this.doFetch({
        branchId: this.current,
        action: 'next',
        currentPage: 0,
      });
    },
    async page() {
      this.$router.push({
        path: 'telecom',
        query: { page: this.page },
      });
      await this.doFetch({
        branchId: this.current,
        action: this.action,
        currentPage: this.paginationPage,
      });
    },
  },
  components: {},
  async mounted() {
    await this.doFetch({
      branchId: this.current,
      action: 'next',
      currentPage: this.paginationPage,
    });
  },
};
